// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const LOGIN_BACK_TO_VERIFICATION = "< Sp\u00e4\u0165 na zadanie SMS k\u00f3du";
export const LOGIN_BUTTON = "Prihl\u00e1s ma";
export const LOGIN_CALL_SUPPORT = "Zavolaj na podporu Modr\u00e9ho Kon\u00edka:";
export const LOGIN_CHANGE_PHONE_NUMBER = "Zmena telef\u00f3nneho \u010d\u00edsla";
export const LOGIN_CHANGE_PHONE_NUMBER_INFO = "Pre zmenu telef\u00f3nneho \u010d\u00edsla je potrebn\u00e9, aby \u0165a manu\u00e1lne overili na\u0161e administr\u00e1torky.";
export const LOGIN_I_FORGOT_PASSWORD = "Zabudla som heslo.";
export const LOGIN_NO_PHONE_VERIFICATION = "Do svojho \u00fa\u010dtu si sa dlh\u0161iu dobu neprihl\u00e1sila. S\u00fa\u010dasne vo svojom \u00fa\u010dte nem\u00e1\u0161 pridan\u00e9 telef\u00f3nne \u010d\u00edslo pre overenie.";
export const LOGIN_NOT_REGISTERED_YET_THEN_url = "E\u0161te nem\u00e1\u0161 login a heslo? %(url)s";
export const LOGIN_OTHER_PHONE_NUMBER = "M\u00e1m in\u00e9 telef\u00f3nne \u010d\u00edslo";
export const LOGIN_PASSWORD = "Heslo:";
export const LOGIN_PHONE_VERIFICATION = "Overenie telef\u00f3nneho \u010d\u00edsla";
export const LOGIN_PHONE_VERIFICATION_CONTACTING_YOU_ON_number = "K\u00f3d ti pr\u00edde na \u010d\u00edslo %(number)s";
export const LOGIN_PHONE_VERIFICATION_INFO = "Kv\u00f4li ochrane tvojho \u00fa\u010dtu pred neopr\u00e1vnen\u00fdm prihl\u00e1sen\u00edm je potrebn\u00e9 manu\u00e1lne overenie, pri ktorom ti k \u00fa\u010dtu prid\u00e1me telef\u00f3nne \u010d\u00edslo.";
export const LOGIN_REGISTER_HERE = "Zaregistruj sa tu zadarmo!";
export const LOGIN_SMS_VERIFICATION_INFO = "Do svojho \u00fa\u010dtu si sa dlh\u0161iu dobu neprihl\u00e1sila. Kv\u00f4li ochrane tvojho \u00fa\u010dtu pred neopr\u00e1vnen\u00fdm prihl\u00e1sen\u00edm potrebujeme, aby si overila prihl\u00e1senie pomocou SMS k\u00f3du.";
export const LOGIN_SUPPORT_NUMBER = "+421 948 333 191";
export const LOGIN_USERNAME_OR_EMAIL = "Prihlasovacie meno alebo email:";
export const LOGIN_YOU_DID_NOT_ENTER_PASSWORD = "Nezadala si heslo.";
export const LOGIN_YOU_DID_NOT_ENTER_USERNAME = "Nezadala si prihlasovacie meno alebo email.";
export const PHONE_VERIFICATION_TOKEN_CALL_LABEL = "Overovac\u00ed k\u00f3d z telefon\u00e1tu:";
export const PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_CALL = "K\u00f3d nem\u00e1m, zavolaj mi znovu.";
export const PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_SMS = "K\u00f3d nepri\u0161iel, po\u0161li znovu.";
export const PHONE_VERIFICATION_TOKEN_SMS_LABEL = "Overovac\u00ed k\u00f3d z SMS:";
export const PHONE_VERIFICATION_WE_SENT_YOU_TOKEN_SMS = "Poslali sme ti nov\u00fd overovac\u00ed k\u00f3d.";
export const SIGNUP_PHONE_VALIDATE_TOKEN = "Odo\u0161li";

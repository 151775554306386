// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const HOMEPAGE_HEADING = "Po\u010f na Modr\u00e9ho kon\u00edka!";
export const HOMEPAGE_PARA_1_CONTENT = "Modr\u00fd kon\u00edk nav\u0161tevuj\u00fa tis\u00edce \u017eien z ka\u017ed\u00e9ho k\u00fata Slovenska. Preto ti tu r\u00fdchlo porad\u00edme prakticky s \u010d\u00edmko\u013evek oh\u013eadom tehotenstva, zdravia, det\u00ed, rodiny a \u017eivota \u017eeny celkovo. V\u017edy sa n\u00e1jde p\u00e1r diev\u010dat, ktor\u00e9 rie\u0161ili nie\u010do podobn\u00e9 ako ty :)";
export const HOMEPAGE_PARA_1_HEADING = "M\u00e1me spolo\u010dn\u00e9 t\u00e9my...";
export const HOMEPAGE_PARA_2_CONTENT = "Schv\u00e1lne, sk\u00fas sa na nie\u010do op\u00fdta\u0165 po registr\u00e1cii a odpove\u010f od niektorej z n\u00e1s uvid\u00ed\u0161 o p\u00e1r min\u00fat. Je ve\u013emi fajn pocit poradi\u0165 sa s ostatn\u00fdmi babami.";
export const HOMEPAGE_PARA_2_HEADING = "...a preto si vieme poradi\u0165...";
export const HOMEPAGE_PARA_3_CONTENT = "Okrem diskusi\u00ed m\u00e1me na kon\u00edku aj par\u00e1dny detsk\u00fd baz\u00e1r, kde je viac ako 2 250 000 inzer\u00e1tov. Jednoducho nie\u010do pekn\u00e9 k\u00fapi\u0161 alebo pred\u00e1\u0161.";
export const HOMEPAGE_PARA_3_HEADING = "...alebo pom\u00f4c\u0165 obstara\u0165 v\u00fdbavi\u010dku";
export const HOME_SIGNUP_BAZAAR = "pravidlami baz\u00e1ra";
export const HOME_SIGNUP_I_AGREE_WITH_SITE_RULES_bazaar_link_common_link_privacy_link = "Registr\u00e1ciou s\u00fahlas\u00edm so %(common_link)s, %(bazaar_link)s a %(privacy_link)s.";
export const HOME_SIGNUP_PRIVACY = "Z\u00e1sadami ochrany osobn\u00fdch \u00fadajov";
export const HOME_SIGNUP_VOP = "V\u0161eobecn\u00fdmi podmienkami pou\u017e\u00edvania";
